import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    association: '',
    pageTitle: '',
    pageIcon: '',
    phone: null,
    token: null,
    loading: false,
    ships: {},
    shipsUser: {},
    megaquotas: {},
    avisos: {},
    aviso: {},
    timetables: {},
    expirations: {},
    closed_seasons: {},
    quotas: {},
    gestcuotas: {},
    gestcuota_all_years: {},
    quota_all_years: {},
    efforts: {},
    sizes: {},
    species: {},
    modules: [],
    hidden_modules: [],
    modal: {
      state: false,
      title: '',
      text: ''
    },
    welcome: true,
    lastNotificationsUpdate: 0,
    lastQuotasUpdate: 0,
    lastCapturesUpdate: 0,
    drawer: false,
    entity_type: '',
    auctions_week: [],
    auctions_day: [],
    global_auctions_stats: [],
    global_auctions_stats_main_port: 0,
    global_auctions_stats_ports: [],
    ports_remote_control_buyer_purchases: [],
    captures: [],
    protectedZones: [],

    idStatistic: null,
    screen: null,
    browser: null,
    browserVersion: null,
    browserMajorVersion: null,
    mobile: null,
    os: null,
    osVersion: null,
    cookies: null
  },
  mutations: {
    association (state, payload) {
      state.association = payload
    },
    globalModal (state, payload) {
      state.modal = payload
    },
    phone (state, payload) {
      state.phone = payload
    },
    token (state, payload) {
      state.token = payload
      if (window.FlutterToken) {
        window.FlutterToken.postMessage(payload)
      }
    },
    loading (state, payload) {
      state.loading = payload
    },
    avisos (state, payload) {
      state.avisos = payload
    },
    aviso (state, payload) {
      state.aviso = payload
    },
    captures (state, payload) {
      state.captures = payload
    },
    speciesCaptures (state, payload) {
      state.speciesCaptures = payload
    },
    capturesMap (state, payload) {
      state.capturesMap = payload
    },
    timetables (state, payload) {
      state.timetables = payload
    },
    expirations (state, payload) {
      state.expirations = payload
    },
    closed_seasons (state, payload) {
      state.closed_seasons = payload.sort(function (a, b) {
        if (
          a.closed_season_dates[0].start_date >
          b.closed_season_dates[0].start_date
        ) {
          return 1
        } else if (
          a.closed_season_dates[0].start_date <
          b.closed_season_dates[0].start_date
        ) {
          return -1
        } else {
          return 0
        }
      })
    },
    shipsQuotas (state, payload) {
      state.quotas = payload.quotas
      state.ships = Array.from(payload.ships).sort(function sortShips (x, y) {
        return x.name.localeCompare(y.name)
      })
      state.megaquotas = payload.megaquotas
    },
    quotas (state, payload) {
      state.quotas = payload.quotas
    },
    gestcuotas (state, payload) {
      state.gestcuotas = payload
    },
    gestcuota_all_years (state, payload) {
      state.gestcuota_all_years = payload
    },
    shipsUser (state, payload) {
      state.shipsUser = payload
    },
    ships (state, payload) {
      state.ships = payload
    },
    quota_all_years (state, payload) {
      state.quota_all_years = payload
    },
    efforts (state, payload) {
      state.efforts = payload
    },
    gestefforts_ship (state, payload) {
      state.gestefforts_ship = payload
    },
    sizes (state, payload) {
      state.sizes = payload
    },
    species (state, payload) {
      state.species = payload
    },
    modules (state, payload) {
      state.modules = payload.modules
    },
    hidden_modules (state, payload) {
      state.hidden_modules = payload.hidden_modules
    },
    pageTitle (state, payload) {
      state.pageTitle = payload
    },
    pageIcon (state, payload) {
      state.pageIcon = payload
    },
    lastNotificationsUpdate (state, payload) {
      state.lastNotificationsUpdate = payload
    },
    lastQuotasUpdate (state, payload) {
      state.lastQuotasUpdate = payload
    },
    lastCapturesUpdate (state, payload) {
      state.lastCapturesUpdate = payload
    },
    welcome (state, payload) {
      state.welcome = payload
    },
    drawer (state, payload) {
      state.drawer = payload
    },
    entity_type (state, payload) {
      state.entity_type = payload.entity_type
    },
    auctions_week (state, payload) {
      state.auctions_week = payload.auctions
    },
    auctions_day (state, payload) {
      state.auctions_day = payload.auctions
    },
    global_auctions_stats (state, payload) {
      state.global_auctions_stats = payload.auctions
    },
    global_auctions_stats_ports (state, payload) {
      state.global_auctions_stats_main_port = payload.main_port
      state.global_auctions_stats_ports = payload.ports
    },
    ports_remote_control_buyer_purchases (state, payload) {
      state.ports_remote_control_buyer_purchases = payload
    },
    forbiddenZones (state, payload) {
      state.forbiddenZones = payload
    },
    protectedZones (state, payload) {
      state.protectedZones = payload
    }
  },
  actions: {
    showGlobalModal: ({ commit }, payload) => commit('globalModal', Object.assign(payload, { status: true })),
    hideGlobalModal: ({ commit }, payload) => commit('globalModal', { status: false })
  },
  getters: {
    authenticated: state => {
      return state.token != null
    }
  },
  plugins: [createPersistedState({
    key: process.env.VUE_APP_VUEX_KEY
  })]
})
