<template>
  <div>
    <loading-component></loading-component>
    <v-main class="page-avisos">
      <v-container fluid fill-height>
        <v-layout justify-center align-center>
          <v-flex xs12 sm10>
            <v-container id="container-avisos">
              <transition
                name="slideInUp"
                v-for="(aviso, index) in avisosResultsApi"
                :key="index"
              >
                <v-card
                  flat
                  class="container-aviso"
                  @click.native="gotoAviso(aviso.id)"
                  :class="{
                    read: aviso.read_status != 'unread',
                    unread: aviso.read_status == 'unread',
                  }"
                >
                  <v-row no-gutters>
                    <v-col cols="2">
                      <v-avatar
                        :color="asignAvatarColor(aviso.sender.id)"
                        class="circle-sender-aviso"
                      >
                        {{ aviso.sender.key[0].toUpperCase() }}
                      </v-avatar>
                    </v-col>
                    <v-col cols="7">
                      <div class="sender-aviso">{{ aviso.sender.name }}</div>
                      <div class="subject-aviso">{{ aviso.subject }}</div>
                      <div
                        class="container-files-aviso"
                        v-if="aviso.attached_files.length > 0"
                      >
                        <transition
                          name="slideInUp"
                          v-for="(archivo, index) in aviso.attached_files"
                          :key="index"
                        >
                          <v-card v-if="index < 2" class="file-aviso"
                            ><img
                              :src="getImgTypeFile(archivo.file)"
                              class="img-type-file"
                            />
                            <div class="name-file">
                              {{ getNameFile(archivo.name) }}
                            </div></v-card
                          >
                        </transition>
                        <div
                          v-if="aviso.attached_files.length > 2"
                          class="extra-avisos"
                        >
                          +{{ aviso.attached_files.length - 2 }}
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="3" class="date-aviso">{{
                      customDateFormat(
                        aviso.sended.slice(8, 10),
                        aviso.sended.slice(5, 7),
                        aviso.sended.slice(0, 4)
                      )
                    }}</v-col>
                  </v-row>
                </v-card>
              </transition>
              <v-container
                v-on:click="load_more_avisos()"
                class="container-button"
                v-if="avisosApi.next != null"
              >
                <img
                  src="../assets/i/icons/show_more.png"
                  class="btn-load-more-avisos"
                />
              </v-container>
              <v-container class="no_avisos" v-if="avisosApi.count <= 0">
                No tienes avisos
              </v-container>
            </v-container>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import LoadingComponent from '../components/LoadingComponent.vue'

export default {
  name: 'avisos',
  components: {
    LoadingComponent
  },
  data () {
    return {
      show: false,
      title: 'Mis avisos',
      page: 1,
      avisos: undefined
    }
  },
  computed: {
    avisosResultsApi () {
      if (this.avisos !== undefined) {
        if (this.page === 0) {
          this.page = 1
          this.avisos = this.$store.state.avisos.results
        }
        if (
          JSON.stringify(this.avisos[this.avisos.length - 1]) !==
            JSON.stringify(
              this.$store.state.avisos.results[this.$store.state.avisos.results.length - 1]
            ) &&
          this.page > 1
        ) {
          this.avisos = this.avisos.concat(this.$store.state.avisos.results)
        }
        return this.avisos
      } else {
        this.avisos = this.$store.state.avisos.results
        return this.$store.state.avisos.results
      }
    },
    avisosApi () {
      return this.$store.state.avisos
    }
  },
  methods: {
    gotoAviso (id) {
      this.page = 1
      this.$api.avisos(1)
      this.avisos = this.$store.state.avisos.results
      this.$router.push({
        name: 'aviso',
        params: {
          id
        }
      })
    },
    customDateFormat (day, month, year) {
      var date = '0/00/0000'
      var mesesAbreviados = [
        'Ene',
        'Feb',
        'Mar',
        'Abr',
        'May',
        'Jun',
        'Jul',
        'Ago',
        'Sep',
        'Oct',
        'Nov',
        'Dic'
      ]
      var currentYear = new Date().getFullYear()
      if (year === currentYear.toString()) {
        date = day + ' ' + mesesAbreviados[month - 1] + '.'
      } else {
        date = day + '/' + month + '/' + year.slice(2, 4)
      }
      return date
    },
    asignAvatarColor (userId) {
      var colors = [
        '#ee5e00',
        '#ff9a00',
        '#e7e400',
        '#13e700',
        '#00e28c',
        '#00bae5',
        '#0002ff',
        '#8b00ff',
        '#e400a9',
        '#de0000'
      ]
      return colors[userId.toString().slice(-1)]
    },
    getNameFile (file) {
      var name = file.split('/')[file.split('/').length - 1]
      return name
    },
    getExtensionFile (file) {
      var extension = file
        .split('/')[file.split('/').length - 1].split('.')[1]
        .toLowerCase()
      return extension
    },
    getImgTypeFile (file) {
      var extensionFile = this.getExtensionFile(file)
      var extensionsTextFile = ['odt', 'txt', 'doc', 'docx']
      var extensionsImgFile = ['jpeg', 'jpg', 'png', 'svg', 'gif']
      var extensionsCalcFile = ['csv', 'ods', 'xls', 'xlt', 'xml']
      var srcImg
      var images = require.context('../assets/i/icons/', false, /\.png$/)

      if (extensionFile === 'pdf') {
        srcImg = images('./pdf.png')
      } else if (extensionsImgFile.includes(extensionFile)) {
        srcImg = images('./img.png')
      } else if (extensionsTextFile.includes(extensionFile)) {
        srcImg = images('./word.png')
      } else if (extensionsCalcFile.includes(extensionFile)) {
        srcImg = images('./excel.png')
      } else {
        srcImg = images('./file.png')
      }
      return srcImg
    },
    load_more_avisos () {
      this.page++
      this.$api.avisos(this.page)
    },
    load_avisos_on_visibility_change () {
      if (document.visibilityState === 'visible') {
        this.page = 0
        this.avisos = undefined
        if (
          new Date().getTime() - this.$store.state.lastNotificationsUpdate >
          process.env.VUE_APP_NOTIFICATIONS_CACHE_TIME * 1000
        ) {
          this.$api
            .avisos()
            .then((response) => {
              this.$store.commit(
                'lastNotificationsUpdate',
                new Date().getTime()
              )
            })
            .catch((error) => {
              if (['403', '404'].indexOf(error.response.status) > -1) {
                this.$store.commit('token', null)
                this.$router.push('/login')
              }
            })
        }
      }
    }
  },
  mounted () {
    this.$api
      .avisos()
      .then((response) => {
        this.avisos = response.data.results
      })
      .catch((error) => {
        if (error.response.status === 403) {
          this.$store.commit('token', null)
          this.$router.push('/login')
        }
      })
    this.show = true
    this.$store.commit('pageTitle', 'Avisos')
    this.$store.commit('pageIcon', 'avisos.svg')
    this.$gtag.pageview(this.$route)
    document.addEventListener(
      'visibilitychange',
      this.load_avisos_on_visibility_change,
      true
    )
  },
  destroyed () {
    document.removeEventListener(
      'visibilitychange',
      this.load_avisos_on_visibility_change,
      true
    )
  }
}
</script>
