<template>
  <div>
    <loading-component></loading-component>
    <v-main v-if="show" class="page-aviso">
      <v-container fluid fill-height>
        <v-layout justify-center align-center>
          <v-flex xs12 sm10>
            <v-container id="container-aviso">
              <v-card id="back-btn" flat @click.native="gotoAvisos()">
                <img
                  src="../assets/i/icons/arrow-left-solid-white.svg"
                  alt="Back"
                  class="logo"
                />
              </v-card>
              <div class="subject-aviso">{{ aviso.subject }}</div>
              <v-row class="head" no-gutters>
                <div class="sender">
                  <v-avatar
                    :color="asign_avatar_color(aviso.sender.id)"
                    class="sender-avatar"
                  >
                    {{ aviso.sender.key[0].toUpperCase() }}
                  </v-avatar>
                  <div class="sender-aviso">{{ aviso.sender.name }}</div>
                </div>
                <div class="date-aviso">{{
                  custom_date_format(
                    aviso.sended.slice(8, 10),
                    aviso.sended.slice(5, 7),
                    aviso.sended.slice(0, 4)
                  )
                }}</div>
              </v-row>
              <markdown-it-vue class="body-aviso" :content="aviso.body" />
              <div class="container-files-aviso">
                <div
                  name="slideInUp"
                  v-for="(archivo, index) in aviso.attached_files"
                  :key="index"
                >
                  <v-card
                    :href="get_url_file(archivo.id)"
                    :download="get_name_file(archivo.name)"
                    class="file-aviso"
                    ><img
                      :src="get_img_type_file(archivo.file)"
                      class="img-type-file"
                    />
                    <div class="name-file">
                      {{ get_name_file(archivo.name) }}
                    </div></v-card
                  >
                </div>
              </div>
            </v-container>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>
<script src="https://unpkg.com/marked@0.3.6"></script>
<script src="https://unpkg.com/lodash@4.16.0"></script>
<script>
import LoadingComponent from "../components/LoadingComponent.vue";
import MarkdownItVue from "markdown-it-vue";
import "markdown-it-vue/dist/markdown-it-vue.css";

export default {
  name: "avisos",
  components: {
    LoadingComponent,
    MarkdownItVue,
  },
  data () {
    return {
      show: false,
      title: "Aviso",
      attached_files: [],
      id: this.$route.params.id
    }
  },
  computed: {
    aviso () {
      return this.$store.state.aviso
    }
  },
  methods: {
    gotoAvisos () {
      this.$router.push({
        name: "avisos",
      })
    },
    custom_date_format (day, month, year) {
      var date = '0/00/0000'
      var meses_abreviados = [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic",
      ]
      var currentYear = new Date().getFullYear();
      if (year === currentYear.toString()) {
        date = day + " " + meses_abreviados[month - 1] + "."
      } else {
        date = day + "/" + month + "/" + year.slice(2, 4)
      }
      return date
    },
    asign_avatar_color (id_user) {
      var colors = [
        '#ee5e00',
        '#ff9a00',
        '#e7e400',
        '#13e700',
        '#00e28c',
        '#00bae5',
        '#0002ff',
        '#8b00ff',
        '#e400a9',
        '#de0000',
      ]
      return colors[id_user.toString().slice(-1)]
    },
    get_name_file (file) {
      var name = file.split("/")[file.split("/").length - 1]
      return name
    },
    get_extension_file(file) {
      var extension = file
        .split("/")
        [file.split("/").length - 1].split(".")[1]
        .toLowerCase()
      return extension
    },
    get_img_type_file (file) {
      var extension_file = this.get_extension_file(file)
      var extensions_text_file = ["odt", "txt", "doc", "docx"]
      var extensions_img_file = ["jpeg", "jpg", "png", "svg", "gif"]
      var extensions_calc_file = ["csv", "ods", "xls", "xlt", "xml"]
      var src_img

      var images = require.context("../assets/i/icons/", false, /\.png$/)
      if (extension_file == "pdf") {
        src_img = images("./pdf.png")
      } else if (extensions_img_file.includes(extension_file)) {
        src_img = images("./img.png")
      } else if (extensions_text_file.includes(extension_file)) {
        src_img = images("./word.png")
      } else if (extensions_calc_file.includes(extension_file)) {
        src_img = images("./excel.png")
      } else {
        src_img = images("./file.png")
      }
      return src_img
    },
    get_url_file (id_file) {
      let archivo = this.attached_files.filter(
        (archivo_aviso) => parseInt(archivo_aviso.id) === parseInt(id_file)
      )
      if (archivo[0]) {
        let url = archivo[0].file
        if (url.substr(0, 5) != "https" && url.substr(0, 4) === "http") {
          let url_fixed = "https" + url.substr(4)
          return url_fixed
        } else {
          return url
        }
      } else {
        return "#"
      }
    },
  },
  beforeMount () {
    this.$api
      .aviso(this.id)
      .then(() => {
        this.show = true
        let aviso = this.$store.state.avisos.results.filter(
          (aviso) => parseInt(aviso.id) === parseInt(this.id)
        );
        this.attached_files = aviso[0].attached_files
      })
      .catch((error) => {
        if (["403", "404"].indexOf(error.status) > -1) {
          this.$store.commit("token", null)
          this.$router.push("/login")
        }
      })
  }
}
</script>
