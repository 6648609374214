<template>
  <v-main class="page-specie-detail">
    <v-container fluid fill-height>
      <v-layout justify-center align-center>
        <v-flex class="page-specie-detail-panel" xs12 sm10>
          <v-card class="specie-detail" v-if="specie != {}">
            <img
              src="../assets/i/icons/arrow-left-solid.svg"
              class="return-button"
              @click="prevPage"
            />
            <img
              v-if="specie.img != null"
              class="specie-detail-background"
              :src="get_img_url(specie.img)"
              :alt="specie.spanish_name"
            />
            <v-card-title class="specie-detail-header">
              <div>
                <h3>{{ specie.specie_name_association !== null ? specie.specie_name_association : specie.spanish_name }}</h3>
                <h3>({{ specie.fao_code }})</h3>
              </div>
              <p class="transformed" v-if="specie.forbidden">PROHIBIDA</p>
            </v-card-title>
            <div v-if="specie.notes != ''" class="notes-tag" :class="{notesTagOpen: notesOpen}">
              <div class="notes-tag-title" @click="notesOpen = true">
                Excepciones
              </div>
              <div v-if="notesOpen" class="notes-tag-content">
                <div class="notes-tag-text">
                  {{ specie.notes }}
                </div>
                <v-btn class="notes-tag-close" @click="notesOpen = false">
                  Cerrar
                </v-btn>
              </div>
            </div>
            <v-card-text class="specie-detail-text">
              <v-layout>
                <v-flex xs12>
                  <h3>ORDEN</h3>
                  <label> {{ specie.order }} </label>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs12>
                  <h3>FAMILIA</h3>
                  <label> {{ specie.family }}</label>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs12>
                  <h3>ESPECIE</h3>
                  <label> {{ specie.scientific_name }} </label>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs12>
                  <h3>TIPO</h3>
                  <label>{{ specie.type }}</label>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <div v-if="notesOpen" class="background-black-opacity" @click="notesOpen = false"></div>
  </v-main>
</template>

<script>
export default {
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },
  data () {
    return {
      specie: {},
      especies_data: {},
      notesOpen: false
    }
  },
  computed: {
    species () {
      if (this.$store.state.species.length !== undefined) {
        return this.$store.state.species
      } else {
        return this.especies_data
      }
    }
  },
  methods: {
    get_img_url (url) {
      if (url.substr(0, 5) !== 'https' && url.substr(0, 4) === 'http') {
        let urlFixed = 'https' + url.substr(4)
        return urlFixed
      } else {
        return url
      }
    },
    prevPage () {
      this.$router.push({
        name: 'species'
      })
    },
    search_specie (species) {
      return species.filter((specie) => {
        return specie.id === parseInt(this.id)
      })[0]
    }
  },
  mounted () {
    if (this.$store.state.species.length === 0) {
      this.$api
        .species()
        .then((response) => {
          this.specie = this.search_specie(response.data)
        })
        .catch((error) => {
          if (error.response.status === '403') {
            this.$store.commit('token', null)
            this.$router.push('/login')
          }
        })
    }
    this.specie = this.search_specie(this.$store.state.species)
  }
}
</script>
