var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loading-component'),_c('v-main',{staticClass:"page-expirations"},[_c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm10":""}},[_c('v-container',{staticClass:"container-ship-expirations"},[_vm._l((_vm.expirationsApi),function(expiration,key_expiration){return _c('transition',{key:key_expiration,attrs:{"name":"slideInUp"}},[_c('div',[_c('div',{staticClass:"container-expiration",on:{"click":_vm.show_more}},[_c('div',{staticClass:"expiration-title"},[_c('div',{staticClass:"expiration-mark",class:{
                        hidemark:
                        _vm.expirationCheck(_vm.getOlderDate(expiration.ship_expirations), expiration.expiration_details.reminder) !=
                        'Caducado',
                      }}),_c('div',{staticClass:"expiration-type"},[_vm._v(" "+_vm._s(expiration.expiration_details.name)+" ")]),_c('div',{class:{
                        expired:
                          _vm.expirationCheck(_vm.getOlderDate(expiration.ship_expirations), expiration.expiration_details.reminder) ==
                          'Caducado',
                      }},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.getOlderDate(expiration.ship_expirations)))+" ")])]),_c('v-expand-transition',[_c('div',{staticClass:"expiration_details"},[_c('div',{staticClass:"ship_expirations"},_vm._l((Object.keys(expiration.ship_expirations)),function(ship,index_ship){return _c('div',{key:index_ship,staticClass:"ship_expiration",attrs:{"name":"slideInUp"}},[_c('p',[_vm._v(_vm._s(ship))]),_vm._l((Object.values(expiration.ship_expirations[ship])),function(ship_expiration,ship_expiration_index){return _c('div',{key:ship_expiration_index,staticClass:"expiration-wrapper"},[(ship_expiration.expiration_date !== null)?_c('div',{staticClass:"expirations-info"},[_c('div',{staticClass:"text"},[_vm._v(_vm._s(ship_expiration.identifier_name))]),_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.formatDate(ship_expiration.expiration_date)))]),_c('div',{staticClass:"expired_expiration_size",class:{
                                expired_expiration:
                                  _vm.expirationCheck(ship_expiration.expiration_date, expiration.expiration_details.reminder) ==
                                  'Caducado',
                              }})]):_vm._e(),(ship_expiration.expiration.expiration_type === 'balsa' && ship_expiration.zafa_expiration_date !== null)?_c('div',{staticClass:"flex"},[_c('div',{staticClass:"text"},[_vm._v("Zafa")]),_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.formatDate(ship_expiration.zafa_expiration_date)))]),_c('div',{staticClass:"expired_expiration_size",class:{
                                expired_expiration:
                                  _vm.expirationCheck(ship_expiration.zafa_expiration_date, expiration.expiration_details.reminder) ==
                                  'Caducado',
                              }})]):_vm._e(),(ship_expiration_index < expiration.ship_expirations[ship].length-1)?_c('div',{staticClass:"separation_line"}):_vm._e()])})],2)}),0),(expiration.expiration_details.competent_administration)?_c('div',{staticClass:"ship_organismo"},[_c('p',[_vm._v("Organismo:")]),_c('p',{staticClass:"text"},[_vm._v(_vm._s(expiration.expiration_details.competent_administration))])]):_vm._e()])])],1)])])}),(Object.keys(_vm.expirationsApi).length <= 0)?_c('v-container',{staticClass:"no-caducidades"},[_vm._v(" No tienes caducidades ")]):_vm._e()],2)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }