<template>
  <div class="main-menu">
    <div class="logo-section">
      <img
        src="../assets/i/simb-para_fondos_oscuros.svg"
        alt="Datalboran"
        class="logo"
      />
    </div>

    <div class="modules">
      <modules ref="modules"></modules>
    </div>

    <div class="copyright-section">
      <div id="logos">
        <img class="logo_andalucia" src="../assets/i/images/logo_junta_andalucia_turq.png" alt="Junta Andalucía"/>
        <img class="logo_ue" src="../assets/i/images/fempa_mono_turq.png" alt="FEMPA"/>
      </div>
      <!-- <div id="copyright-text">© {{ getCopyDate }} <span id="strong">SEALAB SOLUTIONS</span></div> -->
    </div>
  </div>
</template>

<script>
import Modules from '../components/Modules'
import packageInfo from '../../package.json'

export default {
  components: {
    modules: Modules
  },
  name: 'main-menu',
  data () {
    return {
      title: 'Menú',
      ship_owner: '',
      association: '',
      association_id: '',
      appVersion: packageInfo.version
    }
  },
  computed: {
    getCopyDate () {
      return new Date().getFullYear()
    }
  },
  mounted () {
    this.$api
      .modules()
      .then((response) => {
        this.ship_owner = response.data.ship_owner.name
        this.association = response.data.association.name
        this.association_id = response.data.association.id
        this.$store.commit('association', this.association)
      })
      .catch((error) => {
        if (error.response.status === 403) {
          this.$store.commit('token', null)
          this.$router.push('/login')
        }
      })
    this.$api
      .hidden_modules()
      .then((response) => {})
      .catch((error) => {
        if (error.response.status === 403) {
          this.$store.commit('token', null)
          this.$router.push('/login')
        }
      })
    this.$store.commit('pageTitle', 'Menú')
  }
}
</script>
