<template>
  <v-main class="page-quota-effort">
    <img
      src="../assets/i/icons/arrow-left-solid-white.svg"
      class="return-button"
      @click="prevPage"
    />
    <v-container fluid>
      <v-layout justify-center>
        <v-flex xs12>
          <div>
            <v-card class="open detail">

              <v-card-title class="open header">
                <h2>{{ ship.name }}</h2>
                <h3>{{ shipLength }}</h3>
              </v-card-title>

              <v-tabs v-model="currentTab">
                <v-tab v-for="(year, i) in effort_years" :key="i" class="v-tab" :href="'#tab-' + year">
                  {{ year }}
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="currentTab">
                <v-tab-item v-for="year in effort_years" :key="year" :value="'tab-' + year">
                  <div class="open">
                      <div v-for="(effort, i) in sanitized_efforts" :key="i">
                        <div v-if="gest_efforts.length > 0">
                          <div v-for="(gest_effort, j) in gest_efforts" :key="j">
                            <v-card v-if="effort.year == year && gest_effort.year == year">
                              <v-card-text v-if="gest_effort.fishing_type == 'COSTERA MIXTA'">
                                <EffortDetail :gest_effort="gest_effort" :assigned_ef="effort.amount_shore"/>
                              </v-card-text>
                              <v-card-text v-else-if="gest_effort.fishing_type == 'DE PROFUNDIDAD'">
                                <EffortDetail :gest_effort="gest_effort" :assigned_ef="effort.amount_deep"/>
                              </v-card-text>
                            </v-card>
                          </div>
                        </div>
                      </div>
                  </div>
                </v-tab-item>
              </v-tabs-items>

            </v-card>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import EffortDetail from '../components/EffortDetail.vue'

export default {
  name: "effort",
  props: {
    shipId: {
      type: [Number, String],
      required: true
    }
  },
  data () {
    return {
      currentTab: '',
      efforts_assigned: [],
      gest_efforts: [],
      effort_years: [],
      sanitized_efforts: []
    }
  },
  computed: {
    ship () {
      const ships = this.$store.state.ships.results
      for (let i in ships) {
        if (parseInt(ships[i].id) === parseInt(this.shipId)) {
          // if (ships[i].total_length_m) {
          //   ships[i].total_length_m += ' m'
          // }
          return ships[i]
        }
      }
      return null
    },
    shipLength () {
      // console.log(this.ship)
      if (this.ship.total_length_m) {
        return this.ship.total_length_m + ' m'
      } else {
        return ''
      }
    },
    effort () {
      const effort = this.gest_efforts
      for (let q in effort) {
        if (effort[q].ship.id === this.shipId) {
          return effort[q]
        }
      }
      return null
    },
    currentYear () {
      return new Date().getFullYear()
    },
    getYear () {
      let uniqueYears
      if (this.efforts_assigned.length > 0) {
        const years = this.efforts_assigned.map(gest => { return gest.year })
        years.sort()
        uniqueYears = years.filter((value, index, self) => {
          if (value >= this.currentYear - 2) {
            return value !== self[index + 1]
          }
        })
      } else {
        const years = this.gest_efforts.map(gest => { return gest.year })
        years.sort()
        uniqueYears = years.filter((value, index, self) => {
          if (value >= this.currentYear - 2) {
            return value !== self[index + 1]
          }
        })
      }
      return uniqueYears
    }
  },
  methods: {
    prevPage () {
      this.$router.push({
        name: 'shipsEffort'
      })
    },
    standarizeEfforts () {
      // Delete previous years
      let minYear = this.currentYear - 2
      for (let i = 0; i < this.efforts_assigned.length; i++) {
        if (this.efforts_assigned[i].year >= minYear) {
          this.sanitized_efforts.push(this.efforts_assigned[i])
        }
      }
      // Add non existent years to gesteffort array
      let existsGesteffort = false
      for (const ef of this.sanitized_efforts) {
        existsGesteffort = this.gest_efforts.some(ge => ge.year === ef.year)
        if (!existsGesteffort) {
          let date = new Date(ef.last_update_date)
          let lastUpdateDate = date.getDate() + '/0' + (date.getMonth() + 1) + '/' + date.getFullYear()
          this.gest_efforts.push({
            consumed_days: ef.consumed_ar[0],
            fishing_type: 'COSTERA MIXTA',
            last_update: lastUpdateDate,
            year: ef.year
          },
          {
            consumed_days: ef.consumed_ar[1],
            fishing_type: 'DE PROFUNDIDAD',
            last_update: lastUpdateDate,
            year: ef.year
          })
        } else {
          let existsShore = this.gest_efforts.some(ge => ge.fishing_type === 'COSTERA MIXTA')
          let existsDeep = this.gest_efforts.some(ge => ge.fishing_type === 'DE PROFUNDIDAD')
          if (!existsShore) {
            let date = new Date(ef.last_update_date)
            let lastUpdateDate = date.getDate() + '/0' + (date.getMonth() + 1) + '/' + date.getFullYear()
            this.gest_efforts.push({
              consumed_days: ef.consumed_ar[0],
              fishing_type: 'COSTERA MIXTA',
              last_update: lastUpdateDate,
              year: ef.year
            })
          } else if (!existsDeep) {
            let date = new Date(ef.last_update_date)
            let lastUpdateDate = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
            this.gest_efforts.push({
              consumed_days: ef.consumed_ar[1],
              fishing_type: 'DE PROFUNDIDAD',
              last_update: lastUpdateDate,
              year: ef.year
            })
          }
        }
      }
    }
  },
  beforeMount () {
    // console.log(this.$store.state.efforts)
    this.efforts_assigned = this.$store.state.efforts.results
    this.$api.gestefforts_ship(this.ship.id)
      .then((response) => {
        this.gest_efforts = response.data
        // Date formatting
        let date
        this.gest_efforts.forEach(el => {
          date = el.last_update.split('-')
          el.last_update = date[2] + '/' + date[1] + '/' + date[0]
        })

        if (this.efforts_assigned.length <= 0) {
          this.efforts_assigned = [{ 'year': this.getYear[this.getYear.length - 1] }]
        }

        if (this.efforts_assigned[0]) {
          this.standarizeEfforts()
        }

        // Predefined tab
        this.effort_years = this.getYear
        if (this.effort_years.includes(this.currentYear)) {
          this.currentTab = 'tab-' + this.currentYear
        } else {
          this.currentTab = 'tab-' + this.getYear[this.getYear.length - 1]
        }
      })
      // console.log(this.efforts_assigned)
  },
  mounted () {
    this.$store.commit('pageTitle', 'Esfuerzos')
    this.$store.commit('pageIcon', 'efforts.svg')
  },
  components: { EffortDetail }
}
</script>
