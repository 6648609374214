<template>
  <transition name="slide-down">
    <div v-if="show" id="splash">
      <div class="content">
        <img
          src="../assets/i/images/datalboran-splash.png"
          alt="Pantalla de carga"
        >
        <!-- <div class="logo-wrapper">
          <img
            src="../assets/i/vert-para_fondos_oscuros.svg"
            alt="Datalboran"
            class="logo"
          />
        </div> -->
        <!-- <v-container id="official_logos"> -->
          <!-- <v-row>
            <v-col><img src="../assets/i/images/logo_galp_vertical.png"></v-col>
            <v-col><img src="../assets/i/images/logo_galp.png"></v-col>
            <v-col><img src="../assets/i/images/logo_galp_costaluz.png"></v-col>
          </v-row> -->
          <!-- <v-row >
            <div class="text">Proyecto cofinanciado en un 85% por el Fondo Europeo Marítimo y de la Pesca</div>
          </v-row> -->
          <!-- <v-row>
            <img src="../assets/i/images/logo_junta_andalucia_turq.png">
            <img src="../assets/i/images/fempa_mono_turq.png">
          </v-row> -->
        <!-- </v-container> -->
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  props: ['time'],
  data () {
    return {
      show: 1
    }
  },
  mounted () {
    setTimeout(() => {
      this.show = false
    }, this.time)
  }
}
</script>
