<template>
  <div id="sidebar">
    <v-navigation-drawer app right v-model="drawer">
      <div class="sidebar-close" @click.stop="drawer = !drawer">
        <img
          src="../assets/i/icons/cross.svg"
          alt="Cerrar"
          class="icon-cerrar"
        />
      </div>

      <modules ref="modules"></modules>

      <div class="copyright-section">
        <!-- <div id="copyright-text">© {{ getCopyDate }} <span id="strong">SEALAB SOLUTIONS</span></div> -->
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import Modules from './Modules'
import packageInfo from '../../package.json'

export default {
  components: {
    modules: Modules
  },
  name: 'sidebarComponent',
  data () {
    return {
      appVersion: packageInfo.version

    }
  },
  computed: {
    drawer: {
      get () {
        return this.$store.state.drawer
      },
      set (value) {
        this.$store.commit('drawer', value)
      }
    },
    getCopyDate () {
      return new Date().getFullYear()
    }
  },
  methods: {}
}
</script>
