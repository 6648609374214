<template>
  <div id="app">
    <v-app :class="`route-${this.$router.currentRoute.name}`">
      <splash-screen time="3000"></splash-screen>
      <loading-component></loading-component>

      <v-toolbar text min-height="70px" max-height="70px" class="topbar" v-if="showTopBar">
        <img v-if="pageIcon != ''" class="icon-toolbar" :src="require('./assets/i/icons/modules/' + pageIcon)" />
        <v-toolbar-title>
          {{ pageTitle }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn class="btn-menu-open" @click.stop="drawerToggle()">
          <img src="./assets/i/icons/menu_icon.svg" alt="Menú" class="icon-menu" />
        </v-btn>
      </v-toolbar>

      <sidebar v-if="$store.getters.authenticated" ref="sidebar"></sidebar>

      <router-view />

      <v-dialog v-model="globalModal.status" persistent max-width="290">
        <v-card>
          <v-card-title class="headline">{{ globalModal.title }}</v-card-title>
          <v-card-text>
            <span v-html="globalModal.text"></span>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click.native="$store.dispatch('hideGlobalModal')">Aceptar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
import LoadingComponent from './components/LoadingComponent.vue'
import Sidebar from './components/Sidebar'
import SplashScreen from './components/SplashScreen'

export default {
  components: {
    SplashScreen,
    LoadingComponent,
    sidebar: Sidebar
  },
  data () {
    return {
      showTopBar: false

    }
  },
  watch: {
    // immediate: true,
    $route (to, from) {
      let moduleRoute = this.$route.path.split('/')[1]
      if (moduleRoute === '') {
        moduleRoute = 'menu'
      }

      if (to.path.split('/')[1] !== from.path.split('/')[1]) {
        if (this.$store.idStatistic != null) {
          this.$api.updateStatistic(this.$store.idStatistic)
        }
        this.$api.createStatistic(moduleRoute)
          .then((response) => {
            this.$store.idStatistic = response.data.id
          })
          .catch((error) => {
          })
      }
      this.checkToolbarVisibility(to)
    }
  },
  computed: {
    modules () {
      return this.$store.state.modules
    },
    globalModal () {
      return this.$store.state.modal
    },
    pageTitle () {
      return this.$store.state.pageTitle
    },
    pageIcon () {
      return this.$store.state.pageIcon
    }
  },
  methods: {
    checkModule (moduleName) {
      const index = this.modules.map((e) => e.name).indexOf(moduleName)
      return index > -1
    },
    checkToolbarVisibility (route) {
      this.showTopBar =
        route.name !== 'login' &&
        route.name !== 'login-pin' &&
        route.name !== 'menu'
    },
    drawerToggle () {
      this.$store.commit('drawer', !this.$store.state.drawer)
    },

    checkUserData () {
      /**
      * JavaScript Client Detection
      * (C) viazenetti GmbH (Christian Ludwig)
      */

      var unknown = '-'

      // screen
      var screenSize = ''
      if (screen.width) {
        var width = (screen.width) ? screen.width : ''
        var height = (screen.height) ? screen.height : ''
        screenSize += '' + width + ' x ' + height
      }

      // browser
      var nVer = navigator.appVersion
      var nAgt = navigator.userAgent
      var browser = navigator.appName
      var version = '' + parseFloat(navigator.appVersion)
      var majorVersion = parseInt(navigator.appVersion, 10)
      var nameOffset, verOffset, ix

      // Opera
      if ((verOffset = nAgt.indexOf('Opera')) !== -1) {
        browser = 'Opera'
        version = nAgt.substring(verOffset + 6)
        if ((verOffset = nAgt.indexOf('Version')) !== -1) {
          version = nAgt.substring(verOffset + 8)
        }
      }
      // Opera Next
      if ((verOffset = nAgt.indexOf('OPR')) !== -1) {
        browser = 'Opera'
        version = nAgt.substring(verOffset + 4)

        // Legacy Edge
      } else if ((verOffset = nAgt.indexOf('Edge')) !== -1) {
        browser = 'Microsoft Legacy Edge'
        version = nAgt.substring(verOffset + 5)

        // Edge (Chromium)
      } else if ((verOffset = nAgt.indexOf('Edg')) !== -1) {
        browser = 'Microsoft Edge'
        version = nAgt.substring(verOffset + 4)

        // MSIE
      } else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
        browser = 'Microsoft Internet Explorer'
        version = nAgt.substring(verOffset + 5)

        // Chrome
      } else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) {
        browser = 'Chrome'
        version = nAgt.substring(verOffset + 7)

        // Safari
      } else if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
        browser = 'Safari'
        version = nAgt.substring(verOffset + 7)
        if ((verOffset = nAgt.indexOf('Version')) !== -1) {
          version = nAgt.substring(verOffset + 8)
        }

        // Firefox
      } else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
        browser = 'Firefox'
        version = nAgt.substring(verOffset + 8)

        // MSIE 11+
      } else if (nAgt.indexOf('Trident/') !== -1) {
        browser = 'Microsoft Internet Explorer'
        version = nAgt.substring(nAgt.indexOf('rv:') + 3)

        // Other browsers
      } else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
        browser = nAgt.substring(nameOffset, verOffset)
        version = nAgt.substring(verOffset + 1)
        if (browser.toLowerCase() === browser.toUpperCase()) {
          browser = navigator.appName
        }
      }
      // trim the version string
      if ((ix = version.indexOf(';')) !== -1) version = version.substring(0, ix)
      if ((ix = version.indexOf(' ')) !== -1) version = version.substring(0, ix)
      if ((ix = version.indexOf(')')) !== -1) version = version.substring(0, ix)

      majorVersion = parseInt('' + version, 10)
      if (isNaN(majorVersion)) {
        version = '' + parseFloat(navigator.appVersion)
        majorVersion = parseInt(navigator.appVersion, 10)
      }

      // mobile version
      var mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer)

      // cookie
      var cookieEnabled = !!(navigator.cookieEnabled)

      if (typeof navigator.cookieEnabled === 'undefined' && !cookieEnabled) {
        document.cookie = 'testcookie'
        cookieEnabled = (document.cookie.indexOf('testcookie') !== -1)
      }

      // system
      var os = unknown
      var clientStrings = [
        { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
        { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
        { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
        { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
        { s: 'Windows Vista', r: /Windows NT 6.0/ },
        { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
        { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
        { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
        { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
        { s: 'Windows 98', r: /(Windows 98|Win98)/ },
        { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
        { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
        { s: 'Windows CE', r: /Windows CE/ },
        { s: 'Windows 3.11', r: /Win16/ },
        { s: 'Android', r: /Android/ },
        { s: 'Open BSD', r: /OpenBSD/ },
        { s: 'Sun OS', r: /SunOS/ },
        { s: 'Chrome OS', r: /CrOS/ },
        { s: 'Linux', r: /(Linux|X11(?!.*CrOS))/ },
        { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
        { s: 'Mac OS X', r: /Mac OS X/ },
        { s: 'Mac OS', r: /(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
        { s: 'QNX', r: /QNX/ },
        { s: 'UNIX', r: /UNIX/ },
        { s: 'BeOS', r: /BeOS/ },
        { s: 'OS/2', r: /OS\/2/ },
        { s: 'Search Bot', r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/ }
      ]
      for (var id in clientStrings) {
        var cs = clientStrings[id]
        if (cs.r.test(nAgt)) {
          os = cs.s
          break
        }
      }

      var osVersion = unknown

      if (/Windows/.test(os)) {
        osVersion = /Windows (.*)/.exec(os)[1]
        os = 'Windows'
      }

      switch (os) {
        case 'Mac OS':
        case 'Mac OS X':
        case 'Android':
          osVersion = /(?:Android|Mac OS|Mac OS X|MacPPC|MacIntel|Mac_PowerPC|Macintosh) ([\.\_\d]+)/.exec(nAgt)[1]
          break

        case 'iOS':
          osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer)
          osVersion = osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] | 0)
          break
      }

      // flash (you'll need to include swfobject)
      /* script src="//ajax.googleapis.com/ajax/libs/swfobject/2.2/swfobject.js" */
      /*  var flashVersion = 'no check'
    if (typeof swfobject !== 'undefined') {
      var fv = swfobject.getFlashPlayerVersion()
      if (fv.major > 0) {
        flashVersion = fv.major + '.' + fv.minor + ' r' + fv.release
      }
      else  {
        flashVersion = unknown
      }
    } */

      /*       var jscd = {
        screen: screenSize,
        browser: browser,
        browserVersion: version,
        browserMajorVersion: majorVersion,
        mobile: mobile,
        os: os,
        osVersion: osVersion,
        cookies: cookieEnabled
      } */
      this.$store.screen = screenSize
      this.$store.browser = browser
      this.$store.browserVersion = version
      this.$store.browserMajorVersion = majorVersion
      this.$store.mobile = mobile
      this.$store.os = os
      this.$store.osVersion = osVersion
      this.$store.cookies = cookieEnabled
      /* alert(
        'OS: ' + jscd.os + ' ' + jscd.osVersion + '\n' +
    'Browser: ' + jscd.browser + ' ' + jscd.browserMajorVersion +
      ' (' + jscd.browserVersion + ')\n' +
    'Mobile: ' + jscd.mobile + '\n' +
    'Flash: ' + jscd.flashVersion + '\n' +
    'Cookies: ' + jscd.cookies + '\n' +
    'Screen Size: ' + jscd.screen + '\n\n' +
    'Full User Agent: ' + navigator.userAgent
      ) */
    }
  },
  created () {
    this.checkUserData()
  },
  mounted () {
    let moduleRoute = this.$route.path.split('/')[1]
    if (moduleRoute === '') {
      moduleRoute = 'menu'
    }
    if (this.$store.getters.authenticated) {
      this.$api.createStatistic(moduleRoute)
        .then((response) => {
          this.$store.idStatistic = response.data.id
        })
        .catch((error) => {
          console.log(error)
        })
      if (window.flutterToken) {
        window.FlutterToken.postMessage(this.$store.state.token)
      }
      /* window.onpopstate = function (event) {
        console.log('atras')
        if (this.$store.idStatistic != null) {
          this.$api.updateStatistic(this.$store.idStatistic)
        }
        this.$api.createStatistic(moduleRoute)
          .then((response) => {
            this.$store.idStatistic = response.data.id
          })
          .catch((error) => {
            console.log(error)
          })
      } */
    }
    this.checkToolbarVisibility(this.$router.currentRoute)
    document.addEventListener(
      'visibilitychange',
      () => {
        if (this.$store.getters.authenticated) {
          if (
            this.checkModule('app-avisos') &&
            document.visibilityState === 'visible' &&
            this.$route.name !== 'avisos'
          ) {
            if (
              new Date().getTime() - this.$store.state.lastNotificationsUpdate >
              process.env.VUE_APP_NOTIFICATIONS_CACHE_TIME * 1000
            ) {
              this.$api
                .avisos()
                .then((response) => {
                  this.$store.commit(
                    'lastNotificationsUpdate',
                    new Date().getTime()
                  )
                })
                .catch((error) => {
                  if (['403', '404'].indexOf(error.response.status) > -1) {
                    this.$store.commit('token', null)
                    this.$router.push('/login')
                  }
                })
            }
          }

          if (document.visibilityState === 'visible') {
            moduleRoute = this.$route.path.split('/')[1]
            if (moduleRoute === '') {
              moduleRoute = 'menu'
            }
            this.$api.createStatistic(moduleRoute)
              .then((response) => {
                this.$store.idStatistic = response.data.id
              })
              .catch((error) => {
                console.log(error)
              })
          } else {
            if (this.$store.idStatistic != null) {
              this.$api.updateStatistic(this.$store.idStatistic)
            }
          }
        }
      },
      true
    )
  },
  destroyed () {
    if (this.$store.idStatistic != null) {
      this.$api.updateStatistic(this.$store.idStatistic)
      this.$store.idStatistic = null
    }
  }
}
</script>
