<template>
  <v-main class="page-login">
    <v-container fill-height align-content-space-around>
      <v-layout row wrap fill-height align-content-space-arround justify-center>
        <v-flex xs12>
          <header class="header-logo">
            <img
              src="../assets/i/vert-para_fondos_oscuros.svg"
              alt="Datalboran"
              class="logo"
            />
          </header>
        </v-flex>
        <v-flex xs10>
          <p class="headline mb-3">
            Introduzca su número de teléfono móvil para registrarse
          </p>
          <v-text-field
            class="tf-large tf-white"
            clearable
            name="phone"
            label=""
            :counter="9"
            single-line
            dark
            v-model="phone"
            prepend-icon="phone"
            inputmode="numeric"
            mask="#########"
            :error-messages="errors.phone"
          ></v-text-field>
        </v-flex>
        <v-flex xs12>
          <v-btn large light @click="requestToken()">Enviar</v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: 'login-request',
  components: {},
  data () {
    return {
      show: false,
      phone: '',
      demo_phone: '13370330',
      demo_phone2: '998214884',
      demo_pin: 'demo_pin',
      errors: {
        // phone: null
      }
    }
  },
  methods: {
    validate () {
      this.errors = {}
      let valid = true
      if (this.phone === '') {
        this.errors.phone = 'Introduzca su número de móvil'
        valid = false
      } else if (this.phone !== this.demo_phone && this.phone !== this.demo_phone2) {
        if (this.phone.length !== 9) {
          this.errors.phone = 'El número de móvil debe tener 9 digitos'
          valid = false
        }
      }
      return valid
    },
    requestToken () {
      if (this.validate()) {
        this.$store.commit('phone', this.phone)

        if (this.phone === this.demo_phone || this.phone === this.demo_phone2) {
          this.$api
            .pinLogin(this.phone, this.demo_pin)
            .then((response) => {
              this.$router.push('/')
            })
            .catch((error) => {
              this.$store.dispatch('showGlobalModal', {
                title: 'Error',
                text: error.response.data.non_field_errors[0]
              })
            })
        } else {
          this.$api
            .pinRequest(this.phone)
            .then((response) => {
              this.$router.push({
                name: 'login-pin',
                params: { type_message: response.data.type_message }
              })
            })
            .catch((error) => {
              console.log('el error')
              console.log(error.response.status)
              if (error.response.status === 429) {
                this.$store.dispatch('showGlobalModal', {
                  title: 'Error',
                  text: 'Demasiados intentos, vuelve a probar mas tarde'
                })
              } else if (error.response.data) {
                this.$store.dispatch('showGlobalModal', {
                  title: 'Error',
                  text: error.response.data.mobile_phone_number[0]
                })
              } else {
                this.$store.dispatch('showGlobalModal', {
                  title: 'Error',
                  text: 'Algo ha fallado'
                })
              }
            })
        }
      }
    }
  },
  mounted () {
    this.show = true
  }
}
</script>
