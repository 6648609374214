<template>
  <transition name="fade">
    <div v-if="show" id="loading">
      <div class="content">
        <div class="logo-wrapper">
          <img
            src="../assets/i/simb-para_fondos_oscuros.svg"
            alt="Datalboran"
            class="spinner"
          />
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'loading-component',
  computed: {
    show () {
      return this.$store.state.loading
    }
  }
}
</script>
