<template>
  <div>
    <loading-component></loading-component>
    <v-main class="page-expirations">
      <v-container fluid fill-height>
        <v-layout justify-center align-center>
          <v-flex xs12 sm10>
            <v-container class="container-ship-expirations">
              <transition
                name="slideInUp"
                v-for="(expiration, key_expiration) in expirationsApi"
                :key="key_expiration"
              >
                <div>
                  <div class="container-expiration" v-on:click="show_more">
                    <div class="expiration-title">
                      <div class="expiration-mark"
                        :class="{
                          hidemark:
                          expirationCheck(getOlderDate(expiration.ship_expirations), expiration.expiration_details.reminder) !=
                          'Caducado',
                        }"
                      ></div>
                      <div
                        class="expiration-type"
                      >
                        {{ expiration.expiration_details.name }}
                      </div>
                      <div
                        :class="{
                          expired:
                            expirationCheck(getOlderDate(expiration.ship_expirations), expiration.expiration_details.reminder) ==
                            'Caducado',
                        }"
                      >
                        {{
                          formatDate(getOlderDate(expiration.ship_expirations))
                        }}
                      </div>
                    </div>
                    <v-expand-transition>
                      <div class="expiration_details">
                        <div class="ship_expirations">
                          <div
                            name="slideInUp"
                            class="ship_expiration"
                            v-for="(ship, index_ship) in Object.keys(expiration.ship_expirations)"
                            :key="index_ship"
                          >
                            <p>{{ ship }}</p>
                            <div class="expiration-wrapper"
                            v-for="(ship_expiration, ship_expiration_index) in Object.values(expiration.ship_expirations[ship])"
                            :key="ship_expiration_index"
                            >
                              <div
                              v-if="ship_expiration.expiration_date !== null"
                              class="expirations-info"
                              >
                                <div class="text">{{ ship_expiration.identifier_name }}</div>
                                <div class="text">{{ formatDate(ship_expiration.expiration_date) }}</div>
                                <div
                                :class="{
                                  expired_expiration:
                                    expirationCheck(ship_expiration.expiration_date, expiration.expiration_details.reminder) ==
                                    'Caducado',
                                }"
                                class="expired_expiration_size">
                                </div>
                              </div>
                              <div v-if="ship_expiration.expiration.expiration_type === 'balsa' && ship_expiration.zafa_expiration_date !== null"
                                class="flex"
                                >
                                <div class="text">Zafa</div>
                                <div class="text">{{ formatDate(ship_expiration.zafa_expiration_date) }}</div>
                                <div class="expired_expiration_size"
                                :class="{
                                  expired_expiration:
                                    expirationCheck(ship_expiration.zafa_expiration_date, expiration.expiration_details.reminder) ==
                                    'Caducado',
                                }"></div>
                              </div>
                              <div
                                v-if="ship_expiration_index < expiration.ship_expirations[ship].length-1"
                                class="separation_line"
                              ></div>
                            </div>
                          </div>
                        </div>

                        <div
                          v-if="expiration.expiration_details.competent_administration"
                          class="ship_organismo"
                        >
                          <p>Organismo:</p>
                          <p class="text">{{ expiration.expiration_details.competent_administration }}</p>
                        </div>

                      </div>
                    </v-expand-transition>
                  </div>
                </div>
              </transition>
              <v-container class="no-caducidades" v-if="Object.keys(expirationsApi).length <= 0">
                No tienes caducidades
              </v-container>
            </v-container>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import LoadingComponent from '../components/LoadingComponent.vue'
export default {
  name: 'expirationsView',
  components: {
    LoadingComponent
  },
  data () {
    return {
      show: false,
      title: 'Mis avisos',
      page: 1,
      expirations: undefined
    }
  },
  computed: {
    expirationsApi () {
      let json = {}
      // Creacion de array con agrupación de las caducidades barco por nombre de caducidad
      if (this.$store.state.expirations && Array.isArray(this.$store.state.expirations)) {
        for (let index = 0; index < this.$store.state.expirations.length; index++) {
          const element = this.$store.state.expirations[index]
          if (json[element.expiration.name] === undefined) {
            json[element.expiration.name] = { 'expiration_details': '', 'ship_expirations': [] }
          }
          json[element.expiration.name].expiration_details = element.expiration
          const shipName = element.ship.name
          if (json[element.expiration.name].ship_expirations[shipName] === undefined) {
            json[element.expiration.name].ship_expirations[shipName] = []
          }
          json[element.expiration.name].ship_expirations[shipName].push(element)
          json[element.expiration.name].expiration_details = {
            ...json[element.expiration.name].expiration_details,
            reminder: Object.values(json[element.expiration.name].ship_expirations)[0][0].reminder ? parseInt(Object.values(json[element.expiration.name].ship_expirations)[0][0].reminder.split(',')[0]) : 30
          }
        }
      }
      return json
    }
  },
  methods: {
    show_more (event) {
      document
        .getElementsByClassName('container-expiration')
        .forEach((element) => {
          if (event.target.closest('.container-expiration') !== element) {
            element.classList.remove('show_more_information')
          }
        })
      event.target
        .closest('.container-expiration')
        .classList.toggle('show_more_information')
    },
    expirationCheck (dateString, limitDays) {
      if (dateString === null) return 0 // Si la fecha es nula, consideramos que no está expirada
      const now = new Date()
      const dateToCompare = new Date(dateString)

      const thirtyDaysFromNow = new Date(now)
      thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + limitDays)

      if (dateToCompare < now || dateToCompare <= thirtyDaysFromNow) {
        return 'Caducado'
      } else {
        return 'Vigente'
      }
    },
    getOlderDate (expirationsShip) {
      let date = null
      for (const key in expirationsShip) {
        if (expirationsShip.hasOwnProperty(key)) {
          const array = expirationsShip[key]
          // Itera sobre cada objeto dentro del array
          for (const item of array) {
            if ((date === null || item.expiration_date < date)) {
              date = item.expiration_date
            }
            if (item.zafa_expiration_date && (date === null || item.zafa_expiration_date < date)) {
              date = item.zafa_expiration_date
            }
          }
        }
      }
      return date
    },
    formatDate (date) {
      date = date.split('-')
      const formatDate = `${date[2]}/${date[1]}/${date[0].substr(2, 2)}`
      return formatDate
    }
  },
  mounted () {
    this.$api.expirations().catch((error) => {
      if (error.response.status === 403) {
        this.$store.commit('token', null)
        this.$router.push('/login')
      }
    })
    this.$api.ships().catch((error) => {
      if (error.response.status === 403) {
        this.$store.commit('token', null)
        this.$router.push('/login')
      }
    })
    this.show = true
    this.$store.commit('pageTitle', 'Caducidades')
    this.$store.commit('pageIcon', 'expirations.svg')
    this.$gtag.pageview(this.$route)
  }
}
</script>
