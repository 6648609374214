<template>
  <div>
    <div id="google-map" class="google-map" ref="googleMap"></div>
  </div>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader'
import styleColors from '../../src/assets/scss/_variables.scss'

export default {
  props: {
    captures: Array,
    apiKey: String,
    forbiddenZones: Array,
    protectedZones: Array
  },

  data () {
    return {
      google: null,
      map: null,
      protectedZonesLayer: null
    }
  },
  watch: {
    captures: function (newVal, oldVal) {
      this.loadMap(newVal)
    },
    forbiddenZones: function (val) {
      this.loadMap()
    },
    protectedZones: function () {
      this.toggleProtectedZones()
    }

  },
  mounted () {
    this.loadMap([])
  },

  methods: {
    loadMap (items) {
      const loader = new Loader({
        apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
        version: 'weekly',
        libraries: ['places']
      })

      const mapOptions = {
        center: {
          lat: 36.473208,
          lng: -9.729552
        },
        zoom: 4,
        mapTypeId: 'roadmap',
        disableDefaultUI: true,
        styles: [
          {
            'featureType': 'administrative.neighborhood',
            'elementType': 'labels',
            'stylers': [
              { 'visibility': 'off' }
            ]
          },
          {
            'featureType': 'administrative.land_parcel',
            'elementType': 'labels',
            'stylers': [
              { 'visibility': 'off' }
            ]
          },
          {
            featureType: 'poi',
            elementType: 'all',
            stylers: [
              { visibility: 'off' }
            ]
          },
          {
            featureType: 'road',
            elementType: 'all',
            stylers: [
              { visibility: 'off' }
            ]
          },
          {
          }
        ]
      }

      loader
        .load()
        .then((google) => {
          this.google = google
          this.map = new this.google.maps.Map(
            document.getElementById('google-map'),
            mapOptions
          )
          this.setZones()

          this.colorAssignment(items)
          this.setPolygons(items)
        })
        .catch((e) => {})
    },
    colorAssignment (items) {
    /*
      const heatMapColors = styleColors['hm-colors'].split(', ')
      const heatMapMaxOpacity = parseFloat(styleColors['hm-opacity'])
      const colors = []

      let opacity = heatMapMaxOpacity

      heatMapColors.forEach(heatMapColor => {
        colors.push({ color: heatMapColor, opacity: opacity })
        opacity -= 0.05
      });
    */
      const colors = [
        { color: 'rgb(102, 0, 13)', opacity: 0.95 },
        { color: 'rgb(165, 15, 21)', opacity: 0.9 },
        { color: 'rgb(203, 25, 29)', opacity: 0.85 },
        { color: 'rgb(239, 59, 44)', opacity: 0.8 },
        { color: 'rgb(251, 106, 74)', opacity: 0.75 },
        { color: 'rgb(252, 146, 114)', opacity: 0.7 },
        { color: 'rgb(252, 187, 161)', opacity: 0.65 }
      ]

      const polygonsColorQuantity = Math.ceil(items.length / colors.length)
      var count = 0

      items.forEach((item) => {
        count++
        let color = ''
        let opacity = 0

        for (let i = 1; i <= colors.length; i++) {
          if (count <= polygonsColorQuantity * i) {
            color = colors[i - 1].color
            opacity = colors[i - 1].opacity
            break
          }
        }

        item['color'] = color
        item['opacity'] = opacity
      })
    },
    setPolygons (items) {
      let coord = { lat: 60.182203, lng: -18.986541 }

      items.forEach((zone) => {
        const coordPolygon = []

        if (zone.polygon !== '') {
          const coordArray = zone.polygon.split(',')
          let lastCoord = {}
          for (const myCoord of coordArray) {
            const coordinates = myCoord.split(' ')
            if (coordinates[0] !== '') {
              lastCoord = {
                lat: parseFloat(coordinates[0]),
                lng: parseFloat(coordinates[1])
              }
              coord = {
                lat: parseFloat(coordinates[0]),
                lng: parseFloat(coordinates[1])
              }
              coordPolygon.push(lastCoord)
            }
          }

          // Construct the polygon.
          const polygon = new this.google.maps.Polygon({
            paths: coordPolygon,
            strokeColor: zone.color,
            strokeOpacity: 0,
            strokeWeight: 0,
            fillColor: zone.color,
            fillOpacity: zone.opacity,
            zIndex: 1
          })

          polygon.setMap(this.map)
        }
      })

      if (coord.lat !== 60.182203) {
        this.map.setZoom(6)
        this.map.setCenter(coord)
      }
    },
    /**
     * Draw the forbidden zones
     */
    setZones () {
      const fzColor = { color: '#FFEB00', opacity: 0.6 }
      const polygonArray = []

      this.forbiddenZones.forEach(zone => {
        if (zone.polygon !== 0) {
          const polygon = []
          const coords = zone.polygon.split(',')
          for (const coord of coords) {
            const point = coord.split(' ')
            if (point.length > 0) {
              polygon.push({
                lat: parseFloat(point[1]),
                lng: parseFloat(point[0])
              })
            }
          }
          polygonArray.push(polygon)
          const draw = new this.google.maps.Polygon({
            paths: polygon,
            strokeColor: fzColor.color,
            strokeOpacity: fzColor.opacity,
            strokeWeight: 0.5,
            fillColor: fzColor.color,
            fillOpacity: fzColor.opacity,
            zIndex: -1
          })

          draw.setMap(this.map)
        }
      })
    },
    toggleProtectedZones () {
      if (this.google && this.google.maps) {
        if (this.protectedZonesLayer) {
          this.protectedZonesLayer.setMap(null)
          this.protectedZonesLayer = null
        }

        this.protectedZonesLayer = new this.google.maps.Polygon({
          paths: this.convertirCoordenadas(this.protectedZones),
          strokeColor: '#23FF00',
          strokeOpacity: 0.95,
          strokeWeight: 2,
          fillColor: '#77FF00',
          fillOpacity: 0.6
        })

        if (this.protectedZones.length > 0) {
          this.protectedZonesLayer.setMap(this.map)
        }
      }
    },
    convertirCoordenadas (array) {
      return array.map(obj => {
        const coordinates = obj.polygon.split(',').map(coord => {
          const [lng, lat] = coord.trim().split(' ')
          return { lat: parseFloat(lat), lng: parseFloat(lng) }
        })
        return coordinates
      })
    }

  }
}
</script>
